import { useOutletContext } from "react-router-dom";
import ProductTable from "./table/ProductTable";
import { Dispatch, SetStateAction, useEffect } from "react";
import useToolkit from "hooks/useToolkit";
import ShigaButton from "components/common/form/ShigaButton";
import { RootState } from "store/store";

interface ContextType {
  createProductOpen?: boolean;
  setCreateProductOpen: Dispatch<SetStateAction<boolean>>;
  setLogoModalShown: Dispatch<SetStateAction<boolean>>;
  toggleTermsModal: () => void;
}

const Products = () => {
  const { businessProfile, useSelector, refetchProfiles, isVendorBusiness } = useToolkit();
  const { setCreateProductOpen, toggleTermsModal, setLogoModalShown } = useOutletContext<ContextType>()
  const { deleteProductLoading, getProductsLoading } = useSelector((state: RootState) => state.ecom)

  const isValidBusinessLogo = businessProfile?.logo && businessProfile?.logo?.length > 0 && businessProfile?.logo?.includes('https://');

  useEffect(() => {
    refetchProfiles();
    // eslint-disable-next-line
  }, []);

  const handleAddProduct = () => {
    if (isVendorBusiness) {
      if (isValidBusinessLogo) {
        setCreateProductOpen(true);
      } else {
        setLogoModalShown(true);
      }
    } else {
      toggleTermsModal();
    }
  }

  return (
    <div>

      <div className="flex items-center lg:justify-between pb-12 mb-12 border-b border-gray-100 flex-wrap lg:flex-nowrap">

        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Products
        </div>

        <div className="w-full lg:w-max pt-5 lg:pt-0">
          <ShigaButton
            darkBg
            onClick={handleAddProduct}
            disabled={deleteProductLoading || getProductsLoading}
            text={isVendorBusiness ? "Add a Product" : 'Onboard as vendor'}
          />
        </div>
      </div>

      <ProductTable
        setCreateProductOpen={setCreateProductOpen}
      />
    </div>
  );
};

export interface ProductType {
  id: string | number;
  name: string;
  price: number | string;
  currency: string;
  totalItem: number;
  itemSold: number;
  status: string;
  dateCreated: string;
  description: string;
  category: string;
}

export const products = {
  data: {
    meta: {
      size: 5,
      totalItems: 5,
      nextPage: 1,
      previousPage: 0,
    },
    payload: [
      {
        id: 1,
        name: "Burger King’s Hamburger",
        price: 4.99,
        currency: "USD",
        totalItem: 100,
        itemSold: 30,
        status: "Outgoing",
        dateCreated: "2024-07-10",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 2,
        name: "Burger King’s Sandwich",
        price: "300,000",
        currency: "NGN",
        totalItem: 60,
        itemSold: 0,
        status: "Outgoing",
        dateCreated: "2024-07-11",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 3,
        name: "Burger King’s Snack",
        price: 4.79,
        currency: "USD",
        totalItem: 200,
        itemSold: 120,
        status: "Outgoing",
        dateCreated: "2024-07-12",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 4,
        name: "Burger King’s",
        price: 5.29,
        currency: "USD",
        totalItem: 80,
        itemSold: 50,
        status: "Outgoing",
        dateCreated: "2024-07-13",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 5,
        name: "Combo Burger King’s",
        price: 4.99,
        currency: "USD",
        totalItem: 100,
        itemSold: 30,
        status: "Outgoing",
        dateCreated: "2024-07-10",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
    ],
  },
};

export default Products;
