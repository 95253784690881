import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { Input, SelectInput } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";
import { createTerminalOperator, getTerminalDevices, getTerminalOperators, resetTerminalParams } from 'store/terminals/action';


type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const defaultForm = {
   password: '',
   deviceId: '',
}

const NewOperatorModal = ({ isOpen, setIsOpen }: Props) => {
   const [form, setForm] = useState(defaultForm)
   const { dispatch, useSelector } = useToolkit();
   const {
      getDevicesLoading,
      createOperatorLoading,
      createOperatorData,
      getDevicesData:
      devices
   } = useSelector((state: RootState) => state.terminals)

   const formIsValid = form.password?.length > 7;

   const tips = [
      'This action will create a new operator and generate a token for use on devices assigned to your business.',
      'Once an operator is created for your business, it cannot be edited - only deleted.'
   ]

   const handleSubmit = (e: any) => {
      e.preventDefault();
      dispatch(createTerminalOperator({ id: form.deviceId, password: form.password }))
   }

   useEffect(() => {
      setForm(defaultForm)
      dispatch(getTerminalDevices({ page: 1, limit: 999 }));
      // eslint-disable-next-line
   }, [])

   useEffect(() => {
      if (createOperatorData?.success === true) {
         setForm(defaultForm)
         setIsOpen(false);
         dispatch(getTerminalOperators({ page: 1, limit: 9 }));

         setTimeout(() => {
            dispatch(resetTerminalParams())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [createOperatorData])

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Generate an Operator'
         isButtonLoading={false}>

         <div className='w-full relative'>

            <div style={{ background: ' linear-gradient(0deg, #FFFBF5, #FFFBF5), #FFF7EB' }}
               className='p-4 rounded-2xl space-y-4 mb-8'>
               {tips.map((item, idx) => (
                  <span key={idx} className="flex items-start">

                     <div className="pt-1 mr-2.5">
                        <IconInfo className="w-4 h-4" />
                     </div>

                     <p className="text-shiga-brown-10">
                        {item}
                     </p>

                  </span>
               ))}
            </div>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-y border-shiga-gray-50 space-y-7">

                  <SelectInput
                     required={false}
                     value={form.deviceId}
                     label='Assign to a Device'
                     onChange={(e: any) => setForm({ ...form, deviceId: e.target.value })}>
                     <option value="">Select a Device</option>
                     {devices?.data?.devices.map((item: any) => (
                        <option value={item?.id} key={item?.id}>
                           {item?.name}
                        </option>
                     ))}
                  </SelectInput>

                  <Input
                     type="password"
                     required={true}
                     value={form.password}
                     placeholder='Enter password'
                     label='Enter your account password to confirm'
                     onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                  />

               </div>

               <div className="mt-5">
                  <ShigaButton
                     darkBg
                     fullWidth
                     type='submit'
                     text="Generate Operator"
                     disabled={!formIsValid}
                     loading={getDevicesLoading || createOperatorLoading}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default NewOperatorModal