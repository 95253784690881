
export const GET_DEVICE_TRX_START = "GET_DEVICE_TRX_START";
export const GET_DEVICE_TRX_DONE = "GET_DEVICE_TRX_DONE";
export const GET_DEVICE_TRX_FAILED = "GET_DEVICE_TRX_FAILED";

export const GET_DEVICE_TRX_DETAILS_START = "GET_DEVICE_TRX_DETAILS_START";
export const GET_DEVICE_TRX_DETAILS_DONE = "GET_DEVICE_TRX_DETAILS_DONE";
export const GET_DEVICE_TRX_DETAILS_FAILED = "GET_DEVICE_TRX_DETAILS_FAILED";

export const CREATE_TERMINAL_AGENT_START = "CREATE_TERMINAL_AGENT_START";
export const CREATE_TERMINAL_AGENT_DONE = "CREATE_TERMINAL_AGENT_DONE";
export const CREATE_TERMINAL_AGENT_FAILED = "CREATE_TERMINAL_AGENT_FAILED";

export const GET_TERMINAL_AGENTS_START = "GET_TERMINAL_AGENTS_START";
export const GET_TERMINAL_AGENTS_DONE = "GET_TERMINAL_AGENTS_DONE";
export const GET_TERMINAL_AGENTS_FAILED = "GET_TERMINAL_AGENTS_FAILED";

export const DELETE_TERMINAL_AGENT_START = "DELETE_TERMINAL_AGENT_START";
export const DELETE_TERMINAL_AGENT_DONE = "DELETE_TERMINAL_AGENT_DONE";
export const DELETE_TERMINAL_AGENT_FAILED = "DELETE_TERMINAL_AGENT_FAILED";

export const GENERATE_AGENT_TOKEN_START = "GENERATE_AGENT_TOKEN_START";
export const GENERATE_AGENT_TOKEN_DONE = "GENERATE_AGENT_TOKEN_DONE";
export const GENERATE_AGENT_TOKEN_FAILED = "GENERATE_AGENT_TOKEN_FAILED";

export const RESET_TERMINAL_PARAMS = "RESET_TERMINAL_PARAMS";

export const CREATE_TERMINAL_DEVICE_START = "CREATE_TERMINAL_DEVICE_START";
export const CREATE_TERMINAL_DEVICE_DONE = "CREATE_TERMINAL_DEVICE_DONE";
export const CREATE_TERMINAL_DEVICE_FAILED = "CREATE_TERMINAL_DEVICE_FAILED";

export const GET_DEVICE_DETAILS_START = "GET_DEVICE_DETAILS_START";
export const GET_DEVICE_DETAILS_DONE = "GET_DEVICE_DETAILS_DONE";
export const GET_DEVICE_DETAILS_FAILED = "GET_DEVICE_DETAILS_FAILED";

export const GET_TERMINAL_DEVICES_START = "GET_TERMINAL_DEVICES_START";
export const GET_TERMINAL_DEVICES_DONE = "GET_TERMINAL_DEVICES_DONE";
export const GET_TERMINAL_DEVICES_FAILED = "GET_TERMINAL_DEVICES_FAILED";

export const UPDATE_TERMINAL_DEVICE_START = "UPDATE_TERMINAL_DEVICE_START";
export const UPDATE_TERMINAL_DEVICE_DONE = "UPDATE_TERMINAL_DEVICE_DONE";
export const UPDATE_TERMINAL_DEVICE_FAILED = "UPDATE_TERMINAL_DEVICE_FAILED";

export const DELETE_TERMINAL_DEVICE_START = "DELETE_TERMINAL_DEVICE_START";
export const DELETE_TERMINAL_DEVICE_DONE = "DELETE_TERMINAL_DEVICE_DONE";
export const DELETE_TERMINAL_DEVICE_FAILED = "DELETE_TERMINAL_DEVICE_FAILED";

export const CREATE_TERMINAL_OPERATOR_START = "CREATE_TERMINAL_OPERATOR_START";
export const CREATE_TERMINAL_OPERATOR_DONE = "CREATE_TERMINAL_OPERATOR_DONE";
export const CREATE_TERMINAL_OPERATOR_FAILED = "CREATE_TERMINAL_OPERATOR_FAILED";

export const GET_TERMINAL_OPERATORS_START = "GET_TERMINAL_OPERATORS_START";
export const GET_TERMINAL_OPERATORS_DONE = "GET_TERMINAL_OPERATORS_DONE";
export const GET_TERMINAL_OPERATORS_FAILED = "GET_TERMINAL_OPERATORS_FAILED";

export const DELETE_TERMINAL_OPERATOR_START = "DELETE_TERMINAL_OPERATOR_START";
export const DELETE_TERMINAL_OPERATOR_DONE = "DELETE_TERMINAL_OPERATOR_DONE";
export const DELETE_TERMINAL_OPERATOR_FAILED = "DELETE_TERMINAL_OPERATOR_FAILED";

export const GENERATE_OPERATOR_TOKEN_START = "GENERATE_OPERATOR_TOKEN_START";
export const GENERATE_OPERATOR_TOKEN_DONE = "GENERATE_OPERATOR_TOKEN_DONE";
export const GENERATE_OPERATOR_TOKEN_FAILED = "GENERATE_OPERATOR_TOKEN_FAILED";