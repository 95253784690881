import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconEllipse } from "assets/images/icons/Ellipse.svg";
import IconNotification from "assets/images/icons/notification.svg";
import { useEffect, useState } from 'react';
import Maybe from '../Maybe';
import { useLocation } from "react-router-dom";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import { getNotifications, updateNotification } from "store/dashboard/action";
import { groupNotificationsByDate } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {}

const NotificationsRenderer = (props: Props) => {
   const location = useLocation();

   const { useSelector, dispatch } = useToolkit();
   const [drawerShown, setDrawerShown] = useState(false);
   const [notifications, setNotifications] = useState<any>([]);

   const toggleDrawer = () => setDrawerShown(!drawerShown);
   const { notificationsData, notificationsLoading } = useSelector((state: RootState) => state.dashboard)

   const unreadCount = notificationsData?.data?.notifications?.filter((item: any) => item?.read === false)?.length
   const emptyItems = notificationsLoading === false && notifications?.length === 0;

   const markAsRead = (id: any) => {
      dispatch(updateNotification(id))

      const updatedData = notifications.map((item: any) => ({
         ...item,
         notifications: item.notifications.map((notification: any) =>
            notification.id === id
               ? { ...notification, read: true }
               : notification
         ),
      }));

      setNotifications(updatedData);
   }

   useEffect(() => {
      dispatch(getNotifications())
      // eslint-disable-next-line
   }, [location]);

   useEffect(() => {
      if (notificationsLoading === false && notificationsData !== null) {

         const alertGroup = groupNotificationsByDate(notificationsData?.data?.notifications);
         setNotifications(alertGroup)
      }
      // eslint-disable-next-line
   }, [notificationsLoading, notificationsData]);

   return (
      <div className="relative">

         <button onClick={toggleDrawer}>
            <img
               alt="Notifications"
               src={IconNotification}
               className="w-6 h-6 m-auto object-contain"
            />
         </button>

         <Maybe condition={drawerShown}>

            <div className="p-6 bg-white shadow-xl rounded-2xl absolute top-14 -right-3 w-[350px]">
               <div className="pb-3 flex items-center justify-between border-b border-gray-100">

                  <div className="text-2xl font-ojah font-medium flex items-center">
                     Notifications

                     <Maybe condition={notificationsData !== null}>
                        <span className="ml-2 bg-shiga-red-200 rounded-full text-white font-medium text-sm w-5 h-5 flexed">
                           {unreadCount > 9 ? '9+' : unreadCount}
                        </span>
                     </Maybe>
                  </div>

                  <div onClick={toggleDrawer} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                     <IconClose className="m-auto text-white svg-stroke-gray" />
                  </div>
               </div>

               <div className="min-h-[300px] py-2">

                  <Maybe condition={notificationsLoading}>
                     <div className="table-info">
                        <FontAwesomeIcon icon="spinner" spin />
                        <div className="font-medium">
                           Fetching Notifications
                        </div>
                     </div>
                  </Maybe>

                  <Maybe condition={emptyItems}>
                     <div className="flex flex-col items-center justify-center">
                        <IconEllipse className="mx-auto" />
                        <div className="mt-4 text-center text-sm text-shiga-gray">
                           There are currently no notifications for this business account.
                           Please check again later.
                        </div>
                     </div>
                  </Maybe>

                  <Maybe condition={!notificationsLoading && !emptyItems}>
                     <div className="py-3">

                        {notifications?.map((item: any) => {
                           const { date, notifications } = item;

                           return (
                              <div key={date} className="w-full cursor-pointer border-b border-shiga-gray-75 pb-3">

                                 <div className="text-shiga-gray-300 mb-3">
                                    {date}
                                 </div>

                                 {notifications?.map((item: any) => {
                                    const { id, read, title, body } = item;
                                    const isRead = read === true;
                                    const content = body?.length > 100 ? `${body?.substring(0, 100)}...` : body

                                    return (

                                       <div
                                          key={id}
                                          onClick={() => !isRead && markAsRead(id)}
                                          className="flex items-start justify-between">

                                          <div className="mr-2.5">
                                             {isRead ? <ReadIcon /> : <UnreadIcon />}
                                          </div>

                                          <div className="flex-grow">
                                             <div className={`mb-1.5 text-sm font-medium 
                                             ${isRead ? 'text-shiga-gray-100' : 'text-shiga-dark-100'}`}>{title}</div>

                                             <div className="text-sm text-shiga-gray-300">
                                                {content}
                                             </div>
                                          </div>

                                          <Maybe condition={!isRead}>
                                             <div className="h-full pl-1 flex items-center">
                                                <div className="h-2 w-2 rounded-full bg-shiga-red-200" />
                                             </div>
                                          </Maybe>

                                       </div>
                                    )

                                 })}
                              </div>
                           )
                        })}
                     </div>
                  </Maybe>

               </div>

            </div>
         </Maybe>

      </div>
   )
}

const ReadIcon = () => (
   <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.0429688" width="32" height="32" rx="16" fill="#FAFAFB" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.58331 11.5449C7.58331 10.3023 8.59067 9.29492 9.83331 9.29492H22.1666C23.4093 9.29492 24.4166 10.3023 24.4166 11.5449V20.5449C24.4166 21.7876 23.4093 22.7949 22.1666 22.7949H9.83331C8.59067 22.7949 7.58331 21.7876 7.58331 20.5449V11.5449ZM9.83331 11.1283C9.60319 11.1283 9.41665 11.3148 9.41665 11.5449V12.5617L15.8136 15.7602C15.9309 15.8189 16.069 15.8189 16.1863 15.7602L22.5833 12.5617V11.5449C22.5833 11.3148 22.3968 11.1283 22.1666 11.1283H9.83331ZM22.5833 14.6115L17.0062 17.4C16.3728 17.7167 15.6272 17.7167 14.9938 17.4L9.41665 14.6115V20.5449C9.41665 20.775 9.60319 20.9616 9.83331 20.9616H22.1666C22.3968 20.9616 22.5833 20.775 22.5833 20.5449V14.6115Z" fill="#B4B4B4" />
   </svg>
)


const UnreadIcon = () => (
   <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.0429688" width="32" height="32" rx="16" fill="#F0F6F7" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.58331 11.5449C7.58331 10.3023 8.59067 9.29492 9.83331 9.29492H22.1666C23.4093 9.29492 24.4166 10.3023 24.4166 11.5449V20.5449C24.4166 21.7876 23.4093 22.7949 22.1666 22.7949H9.83331C8.59067 22.7949 7.58331 21.7876 7.58331 20.5449V11.5449ZM9.83331 11.1283C9.60319 11.1283 9.41665 11.3148 9.41665 11.5449V12.5617L15.8136 15.7602C15.9309 15.8189 16.069 15.8189 16.1863 15.7602L22.5833 12.5617V11.5449C22.5833 11.3148 22.3968 11.1283 22.1666 11.1283H9.83331ZM22.5833 14.6115L17.0062 17.4C16.3728 17.7167 15.6272 17.7167 14.9938 17.4L9.41665 14.6115V20.5449C9.41665 20.775 9.60319 20.9616 9.83331 20.9616H22.1666C22.3968 20.9616 22.5833 20.775 22.5833 20.5449V14.6115Z" fill="#096B72" />
   </svg>
)

export default NotificationsRenderer