import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";
import store from "store/store";

let customHeaders = {}

store.subscribe(() => {
    const info = store.getState().auth.userPersist.userInfo?.businessProfile;

    const key = info?.apiKeys?.secretKey;

    if (key && key?.length > 0) {
        customHeaders = { "api-key": key }
    }

}
)

export function getProductOrderDetails(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductOrderDetails + id,
            method: "get",
            customHeaders,
            data: {},
            onStart: actions.GET_ORDER_DETAILS_START,
            onSuccess: actions.GET_ORDER_DETAILS_DONE,
            onError: actions.GET_ORDER_DETAILS_FAILED
        }
    }
}

export function cancelProductOrder(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiCancelProductOrders,
            method: "post",
            customHeaders,
            data: payload,
            onStart: actions.CANCEL_PRODUCT_ORDER_START,
            onSuccess: actions.CANCEL_PRODUCT_ORDER_DONE,
            onError: actions.CANCEL_PRODUCT_ORDER_FAILED
        }
    }
}

export function getProductOrders(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductOrders + id + '/orders',
            method: "get",
            customHeaders,
            data: {},
            onStart: actions.GET_PRODUCT_ORDERS_START,
            onSuccess: actions.GET_PRODUCT_ORDERS_DONE,
            onError: actions.GET_PRODUCT_ORDERS_FAILED
        }
    }
}

export function getAllOrders(params: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetAllOrders + ObjectToQueryString(params),
            method: "get",
            customHeaders,
            data: {},
            onStart: actions.GET_ALL_ORDERS_START,
            onSuccess: actions.GET_ALL_ORDERS_DONE,
            onError: actions.GET_ALL_ORDERS_FAILED
        }
    }
}

export function toggleCourierStatus(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiToggleVendorCouriers + id + '/toggle',
            method: "patch",
            customHeaders,
            data: {},
            onStart: actions.TOGGLE_COURIERS_START,
            onSuccess: actions.TOGGLE_COURIERS_DONE,
            onError: actions.TOGGLE_COURIERS_FAILED
        }
    }
}

export function fetchCouriers() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetVendorCouriers,
            method: "get",
            customHeaders,
            data: {},
            onStart: actions.FETCH_COURIERS_START,
            onSuccess: actions.FETCH_COURIERS_DONE,
            onError: actions.FETCH_COURIERS_FAILED
        }
    }
}

export function toggleProductAvailability(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductDetails + id,
            method: "patch",
            customHeaders,
            data: {},
            onStart: actions.EDIT_PRODUCT_DETAILS_START,
            onSuccess: actions.EDIT_PRODUCT_DETAILS_DONE,
            onError: actions.EDIT_PRODUCT_DETAILS_FAILED
        }
    }
}

export function closeProductEditModal() {
    return {
        type: actions.CLOSE_PRODUCT_EDIT_MODAL,
    }
}

export function openProductEditModal(payload: any) {
    return {
        type: actions.OPEN_PRODUCT_EDIT_MODAL,
        payload,
    }
}

export function selectActiveProduct(payload: any) {
    return {
        type: actions.SELECT_ACTIVE_PRODUCT,
        payload,
    }
}

export function deleteProduct(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiDeleteOjahProduct + id,
            method: "delete",
            customHeaders,
            data: {},
            onStart: actions.DELETE_PRODUCT_START,
            onSuccess: actions.DELETE_PRODUCT_DONE,
            onError: actions.DELETE_PRODUCT_FAILED
        }
    }
}

export function edtProductDetails(id: any, payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductDetails + id,
            method: "put",
            customHeaders,
            data: payload,
            onStart: actions.EDIT_PRODUCT_DETAILS_START,
            onSuccess: actions.EDIT_PRODUCT_DETAILS_DONE,
            onError: actions.EDIT_PRODUCT_DETAILS_FAILED
        }
    }
}

export function getProductDetails(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductDetails + id,
            method: "get",
            customHeaders,
            data: {},
            onStart: actions.GET_PRODUCT_DETAILS_START,
            onSuccess: actions.GET_PRODUCT_DETAILS_DONE,
            onError: actions.GET_PRODUCT_DETAILS_FAILED
        }
    }
}

export function getProducts(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProducts + ObjectToQueryString(payload),
            method: "get",
            customHeaders,
            data: {},
            onStart: actions.GET_PRODUCTS_START,
            onSuccess: actions.GET_PRODUCTS_DONE,
            onError: actions.GET_PRODUCTS_FAILED
        }
    }
}

export function createProduct(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiCreateProduct,
            method: "post",
            customHeaders,
            data: payload,
            onStart: actions.CREATE_PRODUCT_START,
            onSuccess: actions.CREATE_PRODUCT_DONE,
            onError: actions.CREATE_PRODUCT_FAILED
        }
    }
}

export function getProductCategories() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductCategories,
            method: "get",
            customHeaders,
            data: {},
            onStart: actions.GET_PRODUCT_CATEGORIES_START,
            onSuccess: actions.GET_PRODUCT_CATEGORIES_DONE,
            onError: actions.GET_PRODUCT_CATEGORIES_FAILED
        }
    }
}

export function registerOjahVendor() {
    return {
        type: config.apiRequestStart,
        payload: {
            // baseUrl: config.ojahBaseUrl,
            url: config.apiAcceptOjahTerms,
            method: "patch",
            customHeaders,
            data: { acceptedTerms: true },
            onStart: actions.REGISTER_VENDOR_START,
            onSuccess: actions.REGISTER_VENDOR_DONE,
            onError: actions.REGISTER_VENDOR_FAILED
        }
    }
}

export function resetVendorParams() {
    return {
        type: actions.RESET_VENDOR_PARAMS,
    }
}