import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { Input } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";
import { deleteTerminalOperator, resetTerminalParams } from 'store/terminals/action';


type Props = {
   selected: any;
   isOpen: boolean;
   fetchOperators: () => void;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const defaultForm = {
   password: '',
}

const DeleteOperatorModal = ({ isOpen, setIsOpen, selected, fetchOperators }: Props) => {

   const [form, setForm] = useState(defaultForm)
   const { dispatch, useSelector } = useToolkit();
   const { deleteOperatorLoading, deleteOperatorData } = useSelector((state: RootState) => state.terminals)

   const formIsInvalid = form?.password?.length < 8;

   const tips = [
      'You will unlink and unsync this operator from your business account.',
      'This operator will not be able to access any of the devices linked to this account.'
   ]

   const handleSubmit = (e: any) => {
      e.preventDefault();
      dispatch(deleteTerminalOperator({ id: selected?.id, password: form.password }));
   }

   useEffect(() => {
      if (deleteOperatorData?.success === true) {
         setIsOpen(false);
         setForm(defaultForm)
         fetchOperators()

         setTimeout(() => {
            dispatch(resetTerminalParams())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [deleteOperatorData])

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Delete this Operator'
         isButtonLoading={false}>

         <div className='w-full relative'>

            <div className="text-shiga-gray-100 font-medium text-sm pb-3">
               BY DELETING THIS OPERATOR:
            </div>

            <div className='p-4 rounded-2xl space-y-4 mb-5'>

               {tips.map((item, idx) => (
                  <span key={idx} className="flex items-start">

                     <div className="pt-1 mr-2.5">
                        <IconInfo className="w-4 h-4" />
                     </div>

                     <p className="text-shiga-dark-100">{item}</p>
                  </span>
               ))}
            </div>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-y border-shiga-gray-50 space-y-7">

                  <Input
                     type="password"
                     required={true}
                     value={form.password}
                     placeholder='Enter password'
                     label='Enter your account password to confirm'
                     onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                  />

               </div>

               <div className="mt-5">
                  <ShigaButton
                     danger
                     fullWidth
                     type='submit'
                     text="Delete Operator"
                     disabled={formIsInvalid}
                     loading={deleteOperatorLoading}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default DeleteOperatorModal