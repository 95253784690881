import { useState } from "react";
import NewAgentModal from "./NewAgentModal";
import AgentsTable from "./table/AgentsTable";

type Props = {}

const AgentsHomepage = (props: Props) => {
   const [addAgentOpen, setAddAgentOpen] = useState(false);
   const toggleAddAgent = () => setAddAgentOpen(!addAgentOpen)

   const getOperators = () => { }


   return (
      <div className='w-full'>

         <AgentsTable
            toggleAddAgent={toggleAddAgent}
         />

         <NewAgentModal
            isOpen={addAgentOpen}
            getOperators={getOperators}
            setIsOpen={setAddAgentOpen}
         />
      </div>
   )
}

export default AgentsHomepage