import axios from "axios";
import * as config from "data/config";
import * as userActions from "store/auth/user/actionTypes";
import { LOADING_STATUS } from "./loadingActions";
import { useTuitionStore } from "zustand-store/payments/tution";

const api = (store) => (next) => async (action) => {
  const getBaseUrl = (baseUrl) => {
    let apiBaseUrl;
    if (typeof baseUrl !== "undefined") {
      apiBaseUrl = baseUrl;
    } else {
      apiBaseUrl = config.apiBaseUrl;
    }
    return apiBaseUrl;
  };

  const getToken = (customToken) => {
    let apiToken;
    if (typeof customToken !== "undefined") {
      apiToken = customToken;
    } else {
      const loginJwt = store.getState().auth.userPersist.userInfo?.token;
      // const clam = store.getState().auth.userPersist.userInfo;
      apiToken = loginJwt;
    }
    return apiToken;
  };

  if (action.type !== config.apiRequestStart) {
    next(action);
  } else {
    const {
      baseUrl,
      url,
      method,
      data,
      onStart,
      onSuccess,
      onError,
      useLoginJwt,
      customToken,
      customHeaders,
      additionalResponse,
    } = action.payload;
    if (onStart) {
      store.dispatch({ type: onStart });
      store.dispatch({ type: LOADING_STATUS, payload: true });
    }

    const apiBaseUrl = getBaseUrl(baseUrl);

    let http;
    if (typeof useLoginJwt !== "undefined" && useLoginJwt === false) {
      http = axios.create({
        headers: {
          ...customHeaders,
        },
      });
    } else {
      const token = getToken(customToken);
      http = axios.create({
        headers: {
          Authorization: "Bearer " + token,
          ...customHeaders,
        },
      });
    }

    // async function refreshToken() {
    //     const storeRefreshToken = store.getState()?.auth?.userPersist?.userTokens?.refreshToken;
    //     try {
    //         const response = await http.request({
    //             baseURL: apiBaseUrl,
    //             url: config.apiGetRefreshToken,
    //             method: 'POST',
    //             data: {
    //                 refreshToken: storeRefreshToken,
    //             }
    //         });
    //         const newToken = response.data.token;
    //         localStorage.setItem('token', newToken);
    //         return newToken;
    //     } catch (error) {
    //         store.dispatch({ type: userActions.LOGOUT_USER });
    //         throw error;
    //     }
    // }

    next(action); // for debugger logging purpose (in chrome Redux dev tools)

    await http
      .request({
        baseURL: apiBaseUrl,
        url,
        method,
        data,
      })
      .then((response) => {
        store.dispatch({ type: config.apiRequestSuccess, payload: response }); // for debugger logging purpose
        if (onSuccess) {
          if (!additionalResponse) {
            store.dispatch({ type: onSuccess, payload: response.data });
            store.dispatch({ type: LOADING_STATUS, payload: false });
          } else {
            store.dispatch({
              type: onSuccess,
              payload: {
                response: response.data,
                additionalResponse: additionalResponse,
              },
            });
          }
        }
      })
      .catch((error) => {
        store.dispatch({ type: config.apiRequestFailed, payload: error }); // for debugger logging purpose
        if (onError) {
          if (error.response) {
            store.dispatch({ type: onError, payload: error.response?.data });
            store.dispatch({ type: LOADING_STATUS, payload: false });

            if (
              error.response?.data?.message ===
              "Expired Session, Please Login Again"
            ) {
              // window.setTimeout(function () {
              store.dispatch({ type: userActions.LOGOUT_USER });
              useTuitionStore.getState().clearPayload();

              // sessionStorage.clear();
              // localStorage.clear();
              // }, 1000);
            }

            // if (error.response?.data?.message === "Unauthorized") {
            //     // const { customHeaders } = action.payload
            //     try {
            //         // const newToken = await refreshToken();

            //         http.headers['Authorization'] = `Bearer ${newToken}`; // Assuming you're using Bearer tokens
            //         // original request
            //         const retryResponse = await http.request(config);
            //         store.dispatch({ type: config.apiRequestSuccess, payload: retryResponse });
            //         if (!additionalResponse) {
            //             store.dispatch({ type: onSuccess, payload: retryResponse.data });
            //             store.dispatch({ type: LOADING_STATUS, payload: false });
            //         } else {
            //             store.dispatch({
            //                 type: onSuccess, payload: {
            //                     response: retryResponse.data,
            //                     additionalResponse: additionalResponse,
            //                 }
            //             });
            //         }
            //     } catch (retryError) {
            //         store.dispatch({ type: onError, payload: retryError.response?.data });
            //         store.dispatch({ type: LOADING_STATUS, payload: false });
            //         store.dispatch({ type: userActions.LOGOUT_USER });
            //     }
            // }
          } else if (error.request) {
            if (navigator.onLine) {
              store.dispatch({
                type: onError,
                payload: {
                  message: "Error: Invalid request. Please try again later.",
                },
              });
              store.dispatch({ type: LOADING_STATUS, payload: false });
            } else {
              store.dispatch({
                type: onError,
                payload: {
                  message: "No Internet Connection",
                },
              });
              store.dispatch({ type: LOADING_STATUS, payload: false });
            }
          } else {
            store.dispatch({
              type: onError,
              payload: {
                message: "An error occurred. Please try again later.",
              },
            });
            store.dispatch({ type: LOADING_STATUS, payload: false });
          }
        }
      });
  }
};

export default api;
