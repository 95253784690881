import { useEffect } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import { ReactComponent as Sync } from "assets/images/icons/refresh-gray.svg";
import { ReactComponent as Disable } from "assets/images/icons/close-gray.svg";
import { ReactComponent as Enable } from "assets/images/icons/check-outlined.svg";
import { ReactComponent as Delete } from "assets/images/icons/Delete.svg";
import { ReactComponent as Terminal } from "assets/images/icons/terminal-sm.svg";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import EmptyUI from 'components/common/EmptyUi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTerminalDevices, resetTerminalParams, toggleTerminalDevice } from 'store/terminals/action';
import EmptyDevices from "assets/images/icons/empty-terminal.svg";
import ShigaButton from 'components/common/form/ShigaButton';
import FilterPagination from './FilterPagination';
import CustomTableDropdown from 'components/common/CustomTableDropdown';
import DeviceDetailsModal from './details/DeviceDetailsModal';
import DeleteDeviceModal from '../DeleteDeviceModal';

type Props = {
   toggleNewDeviceModal: () => void
}

const DevicesTable = ({ toggleNewDeviceModal }: Props) => {
   const { dispatch, useState, copyItem, isLocalEnv } = useToolkit();
   const navigate = useNavigate();

   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });
   const [selected, setSelected] = useState<any>(null);
   const [detailsOpen, setDetailsOpen] = useState(false);
   const [deleteOpen, setDeleteOpen] = useState(false);

   const toggleDetails = () => setDetailsOpen(!detailsOpen);

   const location = useLocation();
   const {
      getDevicesLoading: devicesLoading,
      getDevicesData:
      devices,
      createDeviceData,
      updateDeviceLoading: isToggling,
      updateDeviceData,

   } = useSelector((state: RootState) => state.terminals);

   const isValidDevices = devicesLoading === false && devices?.data?.devices && devices?.data?.devices?.length > 0;
   const isFirstTimer = devicesLoading === false && !window?.location?.search?.includes('?')

   const fetchDevices = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "status", "string", "", "status");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");
      params = processParams(urlQueryParams, params, "environment", "string", "", "environment");

      setListParams(params);
      dispatch(getTerminalDevices(params));
   }

   const handleSync = (device: any) => {
      // 
   }

   const handleToggle = (device: any) => {
      const payload = {
         id: device?.id,
         status: device?.status === "ACTIVE" ? "DISABLED" : "ACTIVE"
      }
      dispatch(toggleTerminalDevice(payload))
   }

   const handleDelete = (device: any) => {
      setSelected(device);
      setDeleteOpen(true);
   }

   useEffect(() => {
      fetchDevices();
      // eslint-disable-next-line
   }, [location]);

   useEffect(() => {
      if (createDeviceData?.success === true) {
         toggleNewDeviceModal()
         fetchDevices();

         setTimeout(() => {
            dispatch(resetTerminalParams())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [createDeviceData]);

   useEffect(() => {
      if (updateDeviceData?.success === true) {
         fetchDevices();

         setTimeout(() => {
            dispatch(resetTerminalParams())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [updateDeviceData]);

   useEffect(() => {
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);


   return (

      <>
         <div className="pb-24">

            <Maybe condition={!isFirstTimer || isValidDevices}>
               <div className="flex items-center justify-between">

                  <div className="flex-grow">
                     <FilterPagination logs={devices?.data?.meta} />
                  </div>

                  <div className="pb-5 ml-10">
                     <ShigaButton
                        darkBg
                        text="Add a Device"
                        disabled={!isLocalEnv}
                        loading={devicesLoading || isToggling}
                        onClick={() => isLocalEnv ? toggleNewDeviceModal() : null}
                     />
                  </div>
               </div>
            </Maybe>

            <Table>

               <Maybe condition={(devicesLoading === false && !devices?.data?.devices) || (isValidDevices)}>
                  <Table.Head>
                     <th>Terminal</th>
                     <th>Last Synced</th>
                     <th>device code</th>
                     <th>status</th>
                     <th>date added</th>
                     <th></th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     colspan={5}
                     loading={devicesLoading}
                     loadingText="Fetching Devices"
                     data={devices?.data?.devices}
                     total={devices?.data?.devices?.length || 0}
                     emptyUI={
                        <div className='flexed flex-col'>
                           <EmptyUI
                              icon={EmptyDevices}
                              header={isFirstTimer ? "No Device Yet" : "No results found"}
                              subheader={isFirstTimer ? "All devices linked to this business account will be displayed here" : ''}
                           />

                           <Maybe condition={isFirstTimer}>
                              <ShigaButton
                                 darkBg
                                 text="Add a Device"
                                 disabled={!isLocalEnv}
                                 onClick={() => isLocalEnv ? toggleNewDeviceModal() : null}
                              />
                           </Maybe>
                        </div>
                     }
                  />

                  {devicesLoading === false && devices?.data?.devices?.map((device: any, index: number) => {

                     const isActive = device?.status === 'ACTIVE';
                     // const isInactive = device?.status === 'INACTIVE';
                     const isDisabled = device?.status === 'DISABLED';

                     return (
                        <tr
                           key={device.id}
                           className='cursor-pointer font-inter'
                           onClick={() => { setSelected(device); toggleDetails() }}>

                           <td>
                              <div className='flex items-center'>
                                 <span className="mr-3">
                                    <Terminal />
                                 </span>

                                 <div className='flex flex-col'>
                                    <span className="text-shiga-dark-100 font-medium">
                                       Device Name
                                    </span>

                                    <span className="text-shiga-gray-300">
                                       {device?.name}
                                    </span>
                                 </div>
                              </div>
                           </td>

                           <td>
                              <div className='text-sm'>
                                 {device?.lastSynced ?? 'N/A'}
                              </div>
                           </td>

                           <td>
                              <div className="flex items-center">

                                 {device?.code ? `${device?.code?.substring(0, 25)}...` : 'N/A'}

                                 <Maybe condition={device?.code?.length > 0}>
                                    <button className='ml-2' onClick={(e: any) => {
                                       e.stopPropagation();
                                       copyItem(device?.code, 'Device code');
                                    }}>
                                       <CopyIcon />
                                    </button>
                                 </Maybe>

                              </div>
                           </td>

                           <td>
                              <div
                                 className={`border rounded-md capitalize py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                                 ${isActive ? 'border-shiga-green-50 text-shiga-green-100'
                                       : isDisabled ? 'text-shiga-orange-100 border-shiga-orange-50'
                                          : 'border-shiga-red-40 text-shiga-red-100'}`}>
                                 {device?.status?.toLowerCase()}
                              </div>
                           </td>

                           <td>
                              <div className='font-medium'>
                                 {moment(device?.lastAttempted).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                           <td>
                              <CustomTableDropdown
                                 options={[
                                    {
                                       Icon: Sync,
                                       permission: true,
                                       title: 'Sync Device',
                                       onClick: () => handleSync(device),
                                    },
                                    {
                                       permission: true,
                                       onClick: () => handleToggle(device),
                                       Icon: isActive ? Disable : Enable,
                                       title: isActive ? 'Disable Device' : 'Enable Device',
                                    },
                                    {
                                       Icon: Delete,
                                       permission: true,
                                       title: 'Delete Device',
                                       iconClass: 'text-shiga-red-50',
                                       titleClass: 'text-shiga-red-50',
                                       onClick: () => handleDelete(device)
                                    },
                                 ]}
                              />
                           </td>
                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>

         <DeviceDetailsModal
            selected={selected}
            isOpen={detailsOpen}
            setIsOpen={setDetailsOpen}
         />

         <DeleteDeviceModal
            selected={selected}
            isOpen={deleteOpen}
            setIsOpen={setDeleteOpen}
            fetchDevices={fetchDevices}
         />

      </>
   )
}

export default DevicesTable