
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import ContentLoading from "components/common/ContentLoading";
import Maybe from "components/common/Maybe";


const Sidebar = (props: any) => {
   const { selected, copyItem, loading, details } = props;
   const isSuccessful = selected?.status === 'success';

   return (
      <>
         <div className="w-80 pt-10 pl-10 pr-6 min-h-screen flex-shrink-0 hidden lg:block border-r border-gray-100 fixed top-20">

            <div className="mt-6">
               <div className="font-ojah text-xl text-shiga-dark-100 mb-8">
                  Details
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Status</div>
                  <div
                     className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                     ${isSuccessful ? 'border-shiga-green-50 text-shiga-green-100' : 'border-shiga-red-40 text-shiga-red-100'}`}>
                     {isSuccessful ? 'Successful' : 'Failed'}
                  </div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Event Type</div>
                  <div className='rounded-full py-1.5 px-3 flexed bg-shiga-gray-20 text-shiga-gray-10 font-medium w-max'>
                     {selected?.event}
                  </div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Attempts</div>
                  <div className="text-shiga-dark-100">{selected?.retryAttempts}</div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">URL</div>
                  <div className="text-shiga-dark-100 flex items-center">
                     {selected?.url ? selected?.url?.substring(0, 20) + '...' : 'N/A'}

                     <Maybe condition={selected?.url?.length > 0}>
                        <button className='ml-1.5' onClick={() => copyItem(selected?.url, 'URL')}>
                           <CopyIcon />
                        </button>
                     </Maybe>
                  </div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">IP Address</div>
                  <div className="text-shiga-dark-100">
                     <ContentLoading loading={loading} data={selected?.ipAddress ?? 'N/A'} />
                  </div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Last Attempted</div>
                  <div className="text-shiga-dark-100">
                     <ContentLoading loading={loading} data={details?.lastAttempted ?? 'N/A'} />
                  </div>
               </div>

            </div>
         </div>

      </>
   )
}


export default Sidebar