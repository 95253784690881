import { SetStateAction, useEffect } from "react";
import { Dialog } from '@headlessui/react';
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import useToolkit from "hooks/useToolkit";
import Sidebar from "./Sidebar";
import DeviceDetails from "./DeviceDetails";
import { getDeviceDetails } from "store/terminals/action";

type ModalProps = {
    selected: any;
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const DeviceDetailsModal = ({ isOpen, setIsOpen, selected }: ModalProps) => {
    const { dispatch, copyItem } = useToolkit();

    useEffect(() => {
        if (isOpen === true) {
            dispatch(getDeviceDetails(selected?.id))
        }
        // eslint-disable-next-line
    }, [isOpen]);

    return (
        <>
            {(isOpen === true) &&
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50">
                    <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
                    <div className={`mt-24 w-screen bg-white fixed inset-0 rounded-tr-3xl rounded-tl-3xl overflow-hidden shadow-xl animate__animated ${isOpen ? "animate__slideInUp" : "animate__slideOutDown"} animate__faster`}>
                        <div className="w-full h-full bg-white overflow-y-auto">
                            <Dialog.Panel className="min-h-full flex flex-col">

                                <Header
                                    selected={selected}
                                    setIsOpen={setIsOpen}
                                />

                                <div className="flex flex-1 lg:px-0">
                                    <Sidebar
                                        // details={details}
                                        selected={selected}
                                        copyItem={copyItem}
                                    // loading={detailsLoading}
                                    />

                                    <div className="mt-32 lg:ml-[260px] pb-20 lg:flex-grow max-w-[72%] lg:mx-0">

                                        <DeviceDetails selected={selected} />

                                    </div>
                                </div>

                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            }
        </>
    );
}


const Header = (props: any) => (
    <div className="px-6 lg:px-9 py-6 flex items-center justify-between border-b border-gray-100 bg-white z-20 fixed w-full top-0">

        <div className="text-xl text-shiga-dark-100 font-ojah capitalize">
            {props?.selected?.name ?? 'N/A'}
        </div>

        <div onClick={() => props.setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
            <IconClose className="m-auto text-white svg-stroke-gray" />
        </div>
    </div>
)


export default DeviceDetailsModal;
