import { useEffect, useState } from "react";
import ShigaButton from "components/common/form/ShigaButton";
import { Input, SelectInput } from "components/common/form";
import CustomNotePad from "components/custom/CustomNotePad";
import { AddressField } from "components/custom/GoogleAddressField";
import { ReactComponent as IconInfo } from "assets/images/icons/Info.svg";
import Tooltip from "components/common/Tooltip";
import CustomAmountField from "components/custom/CustomAmountField";
import useEcomProvider from "../context/useEcomProvider";
import useToolkit from "hooks/useToolkit";
import { createProduct } from "store/ecom/action";
import { RootState } from "store/store";
import useMediaService from "hooks/useMediaService";
import { toast } from "react-toastify";
import { PHONE_NUMBER_REGEX } from "constants/number.regex";


const ProductDetails = () => {
   const { dispatch, useSelector } = useToolkit();
   const [noteValue, setNoteValue] = useState("");
   const [location, setLocation] = useState('');
   const [categShown, setCategShown] = useState(false);
   const {
      createProductLoading: isCreating,
      productCategoriesData:
      categories,
      productCategoriesLoading,
      createProductData
   } = useSelector((state: RootState) => state.ecom);

   const { uploadedFileLoading: isUploading, uploadOjahProductImage: uploadImage } = useMediaService()

   const { productForm, setProductForm, img1, img2, img3, img4, img5, img6, endSession } = useEcomProvider();
   const { productName, phoneNumber, categoryId, price, weightUnit, weight, quantity } = productForm

   const btnDisabled = productCategoriesLoading || productName === ''
      || noteValue === '' || location === '' || phoneNumber?.length !== 10 || categoryId === ''
      || price === '' || weight === '' || weightUnit === ''
      || weight === '' || quantity === '';

   const formatImgProps = (payload: any) => {
      const { url, type, size, publicId } = payload
      return { url, type, size, publicId }
   }

   const onSubmit = async (e: any) => {
      e.preventDefault();

      const imagesArray = [];

      const payload = {
         productName: productForm.productName,
         categoryId: productForm.categoryId,
         phoneNumber: '+234' + productForm.phoneNumber,
         price: Number(productForm.price),
         quantity: Number(productForm.quantity),
         weight: Number(productForm.weight),
         description: noteValue,
         weightUnit: productForm.weightUnit,
         pickupAddress: location,
      }

      if (img1 !== null) {
         toast('Uploading image')
         const response: any = await uploadImage(img1);
         if (response?.success === true) imagesArray.push(formatImgProps(response));
      }

      if (img2 !== null) {
         toast('Uploading image')
         const response: any = await uploadImage(img2);
         if (response?.success === true) imagesArray.push(formatImgProps(response));
      }

      if (img3 !== null) {
         toast('Uploading image')
         const response: any = await uploadImage(img3);
         if (response?.success === true) imagesArray.push(formatImgProps(response));
      }

      if (img4 !== null) {
         toast('Uploading image')
         const response: any = await uploadImage(img4);
         if (response?.success === true) imagesArray.push(formatImgProps(response));
      }

      if (img5 !== null) {
         toast('Uploading image')
         const response: any = await uploadImage(img5);
         if (response?.success === true) imagesArray.push(formatImgProps(response));
      }

      if (img6 !== null) {
         toast('Uploading image')
         const response: any = await uploadImage(img6);
         if (response?.success === true) imagesArray.push(formatImgProps(response));
      }

      dispatch(createProduct({ ...payload, photos: imagesArray }));
   }

   const weightOptions = [
      { label: 'Kilograms', value: 'Kg' },
      { label: 'Grams', value: 'g' },
      { label: 'Ounces', value: 'oz' },
      { label: 'Pounds', value: 'lb' },
   ]

   useEffect(() => {
      if (createProductData?.success === true) {
         endSession();
      }
      // eslint-disable-next-line
   }, [createProductData])

   return (
      <div className="w-full pb-12">

         <form onSubmit={onSubmit}>
            <div className="space-y-8">
               <Input
                  required
                  value={productForm.productName}
                  label="Name of product"
                  placeholder="Enter a product name"
                  onChange={(e: any) => setProductForm({ ...productForm, productName: e.target.value })}
               />

               <CustomNotePad
                  noteIsRequired={true}
                  noteValue={noteValue}
                  setNoteValue={setNoteValue}
                  notePadLeftLabel="Add a description"
                  notePadPlaceholder="Say a bit more about this product"
               />

               <div className="relative">
                  <div className="absolute right-0 z-10">
                     <Tooltip
                        message={<span>
                           Couriers collect orders <br />
                           from this location for <br />
                           delivery to customers.
                        </span>}>
                        <IconInfo />
                     </Tooltip>
                  </div>

                  <AddressField
                     isRequired
                     addressValue={location}
                     inputLabel="Pick up Location"
                     setAddressValue={setLocation}
                  />
               </div>

               <Input
                  required
                  placeholder="Phone number"
                  label="Pickup Phone number"
                  value={productForm.phoneNumber}
                  inputClass="pl-12"
                  leftIcon={
                     <div style={{ paddingLeft: '5px' }}>
                        +234
                     </div>
                  }
                  onChange={(e: any) => {
                     const value = e.target.value;

                     if (PHONE_NUMBER_REGEX?.test(value)) {
                        setProductForm({ ...productForm, phoneNumber: e.target.value })
                     }
                  }}
               />

               <div className={`${categShown && 'pb-20'}`} onClick={() => setCategShown(!categShown)}>
                  <SelectInput
                     required={true}
                     label="Product Category"
                     value={productForm.categoryId}
                     onChange={(e: any) => setProductForm({ ...productForm, categoryId: e.target.value })}>
                     <option value="">Select Category</option>

                     {categories?.data && categories?.data?.map(({ name, id }: any) => (
                        <option value={id} key={id}>
                           {name}
                        </option>
                     ))}
                  </SelectInput>
               </div>

               <CustomAmountField
                  isFieldRequired
                  inputError={false}
                  transactionCurrency='NGN'
                  leftLabel='Price of Product'
                  flagIconUrl="/flags/ngn.svg"
                  inputAmount={productForm.price}
                  errorText="This is the unit price of this product"
                  setInputAmount={(e: any) => setProductForm({ ...productForm, price: e.target.value })}
               />

               <div className="pb-2 border-b border-shiga-gray-50">
                  <p className="mb-0 text-shiga-gray-200 font-medium text-xs">
                     Inventory
                  </p>
               </div>

               <div className="flex items-center justify-between">
                  <div className="w-[33%]">
                     <SelectInput
                        required={true}
                        value={productForm.weightUnit}
                        label="Unit of measurement"
                        onChange={(e: any) => setProductForm({ ...productForm, weightUnit: e.target.value })}>
                        <option value="">Select Unit</option>
                        {weightOptions.map(({ label, value }) => (
                           <option value={value} key={value}>
                              {label}
                           </option>
                        ))}
                     </SelectInput>
                  </div>

                  <div className="w-[30%]">
                     <Input
                        required
                        placeholder="0.00"
                        value={productForm.weight}
                        label={`Weight (${productForm.weightUnit === 'kg' ? 'KG' : 'G'})`}
                        onChange={(e: any) => setProductForm({ ...productForm, weight: e.target.value })}
                     />
                  </div>

                  <div className="w-[30%]">
                     <Input
                        required
                        type="number"
                        placeholder="0.00"
                        label='Quantity in stock'
                        value={productForm.quantity}
                        onChange={(e: any) => setProductForm({ ...productForm, quantity: e.target.value })}
                     />
                  </div>

               </div>

               <div className="pt-3">
                  <ShigaButton
                     darkBg
                     fullWidth
                     type="submit"
                     disabled={btnDisabled}
                     loading={isUploading || isCreating}
                     text={isUploading ? 'Uploading images' : isCreating ? 'Submitting' : 'Submit for review'}
                  />
               </div>

            </div>

         </form>
      </div>
   )
}

export default ProductDetails