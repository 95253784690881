import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { Input, SelectInput } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import { createTerminalDevice } from 'store/terminals/action';

type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const defaultForm = {
   deviceType: '',
   deviceCode: '',
   deviceName: ''
}

const NewDeviceModal = ({ isOpen, setIsOpen }: Props) => {
   const [form, setForm] = useState(defaultForm)
   const { dispatch, useSelector } = useToolkit();
   const { createDeviceLoading: isLoading, createDeviceData } = useSelector((state: RootState) => state.terminals);

   const formIsValid = form.deviceName?.length > 0 && form.deviceType?.length > 0 && form.deviceCode?.length > 0;

   const deviceTypes = [
      'STADIUM',
      'EVENT',
      'BUS',
      'OTHERS'
   ]

   const handleSubmit = (e: any) => {
      e.preventDefault();

      const payload = {
         name: form.deviceName,
         type: form.deviceType,
         code: form.deviceCode
      }

      dispatch(createTerminalDevice(payload))
   }

   useEffect(() => {
      if (createDeviceData?.success === true) {
         setForm(defaultForm)
         setIsOpen(false);
      }
      // eslint-disable-next-line
   }, [createDeviceData])

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Add a Device'
         isButtonLoading={false}>

         <div className='w-full relative'>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-b border-shiga-gray-50 space-y-7">

                  <Input
                     required={true}
                     value={form.deviceName}
                     placeholder='Enter a device name'
                     label='Device Name'
                     onChange={(e: any) => setForm({ ...form, deviceName: e.target.value })}
                  />

                  <SelectInput
                     required={true}
                     value={form.deviceType}
                     label='Device Type'
                     onChange={(e: any) => setForm({ ...form, deviceType: e.target.value })}>
                     <option value="">Select</option>
                     {deviceTypes.map((item: any) => (
                        <option value={item} key={item}>
                           {item}
                        </option>
                     ))}
                  </SelectInput>

                  <Input
                     type="number"
                     required={true}
                     label='Device Code'
                     value={form.deviceCode}
                     placeholder='ie 8490000398473'
                     onChange={(e: any) => setForm({ ...form, deviceCode: e.target.value })}
                  />

               </div>

               <div className="mt-5">
                  <ShigaButton
                     darkBg
                     fullWidth
                     type='submit'
                     loading={isLoading}
                     disabled={!formIsValid}
                     text={isLoading ? "Submitting" : "Add Device"}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default NewDeviceModal