import Tooltip from "components/common/Tooltip";
import { ReactComponent as IconInfo } from "assets/images/icons/Info.svg";


type MetricsProps = {
   tooltipTitle: string;
   tooltipMsg: string;
   figure: string;
}

type Props = {
   loading: boolean;
   data: any;
}

const DeviceMetrics = ({ loading, data }: Props) => {

   const metrics: MetricsProps[] = [
      {
         tooltipTitle: 'Total Collections',
         tooltipMsg: 'Number of transactions completed on this terminal device till date.',
         figure: '90,000',
      },
      {
         tooltipTitle: 'Current Balance',
         tooltipMsg: 'The funds available on this terminal device right now.',
         figure: 'NGN 35,050',
      },
      {
         tooltipTitle: 'Total Settlements',
         tooltipMsg: 'Total payouts from this terminal device to your settlement account',
         figure: '50,000,000',
      },
      {
         tooltipTitle: 'Total Received',
         tooltipMsg: 'Total amount recieved on this terminal device till date.',
         figure: 'NGN 10,000,000',
      },
   ]


   return (
      <div className='border-b border-shiga-gray-50 lg:pb-5'>
         <div className="w-full flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap mt-7 lg:mt-10">
            {metrics.map(({ tooltipTitle, tooltipMsg, figure }: MetricsProps) => {
               return (
                  <div key={tooltipTitle} className="w-full lg:w-[22%] mb-8 lg:mb-0">
                     <div className='text-xs lg:text-sm text-shiga-gray-300 font-medium flex items-center mb-3'>

                        <span className="text-plg">
                           {tooltipTitle}
                        </span>

                        <Tooltip message={tooltipMsg} isCompact>
                           <span className='ml-2'>
                              <IconInfo className="text-shiga-dark cursor-pointer" />
                           </span>
                        </Tooltip>

                     </div>

                     <div className='flex font-semibold lg:text-2xl'>
                        {figure}
                     </div>

                  </div>
               )
            })}
         </div>

      </div>
   )
}

export default DeviceMetrics