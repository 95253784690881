import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import CustomTableFilter from "components/common/CustomTableFilter";


const FilterPagination = ({ logs }: { logs: any }) => {

   return (
      <div className="mb-4 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
         <div className="w-full lg:w-2/4">
            <CustomTableFilter
               dropdownClass=''
               filters={[
                  {
                     title: "Status",
                     name: "status",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Active",
                           payload: "ACTIVE",
                        },
                        {
                           display: "Inactive",
                           payload: "INACTIVE",
                        },
                        {
                           display: "Disabled",
                           payload: "DISABLED",
                        },
                     ],
                  },
               ]}
            />
         </div>

         <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
            <Pagination meta={logs} />

            <div className="lg:pb-[26px]">
               <SearchBox
                  name='search'
                  extraUrlParams='page=1'
                  placeholder="Search Devices"
               />
            </div>
         </div>
      </div>
   )
}

export default FilterPagination 