import {
   DropdownMenu,
   DropdownMenuContent,
   DropdownMenuItem,
   DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { EllipsisVertical } from 'lucide-react';


const CustomTableDropdown = (props) => {
   const { options, onToggleClick } = props;

   return (
      <DropdownMenu>

         <DropdownMenuTrigger asChild>
            <EllipsisVertical className="size-4" />
         </DropdownMenuTrigger>

         <DropdownMenuContent align="end"
            onClick={(e) => { e?.stopPropagation(); onToggleClick && onToggleClick(); }}>

            {options.map((option, idx) => option?.permission && (

               <DropdownMenuItem key={idx} className="gap-2.5 cursor-pointer">
                  <div onClick={option.onClick} className="flex items-center justify-start bg-shigas-gray-25 cursor-pointer">

                     <span className={option?.iconClass || ''}>
                        <option.Icon />
                     </span>

                     <span className={`ml-3 ${option?.titleClass}`}>
                        {option.title}
                     </span>

                  </div>
               </DropdownMenuItem>

            ))}

         </DropdownMenuContent>
      </DropdownMenu>
   );
};

export default CustomTableDropdown;
