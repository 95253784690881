import { useEffect } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import { ReactComponent as Sync } from "assets/images/icons/refresh-gray.svg";
import { ReactComponent as Delete } from "assets/images/icons/Delete.svg";
import { ReactComponent as Operator } from "assets/images/icons/operator-sm.svg";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import EmptyUI from 'components/common/EmptyUi';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateOperatorToken, getTerminalOperators, resetTerminalParams } from 'store/terminals/action';
import EmptyOperators from "assets/images/icons/user-avatar.svg";
import ShigaButton from 'components/common/form/ShigaButton';
import FilterPagination from './FilterPagination';
import CustomTableDropdown from 'components/common/CustomTableDropdown';
import DeleteOperatorModal from './DeleteOperatorModal';
import OperatorTokenModal from './OperatorTokenModal';

type Props = {
   toggleNewOperatorModal: () => void
}

const OperatorsTable = ({ toggleNewOperatorModal }: Props) => {
   const { dispatch, useState, copyItem, isLocalEnv } = useToolkit();
   const navigate = useNavigate();

   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });
   const [selected, setSelected] = useState<any>(null);
   const [deleteOpen, setDeleteOpen] = useState(false);
   const [tokenModalOpen, setTokenModalOpen] = useState(false);

   const location = useLocation();
   const {
      getOperatorsLoading: operatorsLoading,
      getOperatorsData:
      operators,
      createOperatorData,
      operatorTokenData,
      operatorTokenLoading: tokenLoading
   } = useSelector((state: RootState) => state.terminals);

   const isValidOperators = operatorsLoading === false && operators?.data?.operators && operators?.data?.operators?.length > 0;
   const isFirstTimer = operatorsLoading === false && !window?.location?.search?.includes('?')

   const fetchOperators = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "status", "string", "", "status");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");
      params = processParams(urlQueryParams, params, "environment", "string", "", "environment");

      setListParams(params);
      dispatch(getTerminalOperators(params));
   }

   const handleRegen = () => {
      dispatch(generateOperatorToken(selected?.id))
   }

   const handleDelete = (operator: any) => {
      setSelected(operator);
      setDeleteOpen(true);
   }

   const reset = () => {
      fetchOperators();
      setTimeout(() => {
         dispatch(resetTerminalParams())
      }, 1500);
   }

   useEffect(() => {
      fetchOperators();
      // eslint-disable-next-line
   }, [location]);

   useEffect(() => {
      if (createOperatorData?.success === true) {
         toggleNewOperatorModal()
         reset();
      }
      // eslint-disable-next-line
   }, [createOperatorData]);

   useEffect(() => {
      if (operatorTokenData?.success === true) {
         setTokenModalOpen(false)
         reset();
      }
      // eslint-disable-next-line
   }, [operatorTokenData]);

   useEffect(() => {
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);



   return (

      <>
         <div className="pb-24">

            <Maybe condition={!isFirstTimer || isValidOperators}>
               <div className="flex items-center justify-between">

                  <div className="flex-grow">
                     <FilterPagination
                        logs={operators?.data?.meta}
                     />
                  </div>

                  <div className="pb-5 ml-10">
                     <ShigaButton
                        darkBg
                        disabled={!isLocalEnv}
                        text="Generate an operator"
                        loading={operatorsLoading || tokenLoading}
                        onClick={() => isLocalEnv ? toggleNewOperatorModal() : null}
                     />
                  </div>
               </div>
            </Maybe>

            <Table>

               <Maybe condition={(operatorsLoading === false && !operators?.data?.operators) || (isValidOperators)}>
                  <Table.Head>
                     <th>Operator ID</th>
                     <th>Login Token</th>
                     <th>status</th>
                     <th>active device</th>
                     <th>date generated</th>
                     <th></th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     colspan={5}
                     loading={operatorsLoading}
                     loadingText="Fetching Operators"
                     data={operators?.data?.operators}
                     total={operators?.data?.operators?.length || 0}
                     emptyUI={
                        <div className='flexed flex-col'>
                           <EmptyUI
                              icon={EmptyOperators}
                              header={isFirstTimer ? "No Operators Yet" : "No results found"}
                              subheader={isFirstTimer ? "All operators you generate for your devices on terminals would appear here" : ''}
                           />

                           <Maybe condition={isFirstTimer}>
                              <ShigaButton
                                 darkBg
                                 text="Generate an Operator"
                                 disabled={!isLocalEnv}
                                 onClick={() => isLocalEnv ? toggleNewOperatorModal() : null}
                              />
                           </Maybe>
                        </div>
                     }
                  />

                  {operatorsLoading === false && operators?.data?.operators?.map((operator: any, index: number) => {

                     const isActive = operator?.status === 'ACTIVE';
                     // const isInactive = operator?.status === 'INACTIVE';
                     const isDisabled = operator?.status === 'DISABLED';

                     const hasDevices = operator?.devices?.length > 0;
                     const deviceName = hasDevices ? operator?.devices[0]?.name : 'N/A'

                     return (
                        <tr
                           key={operator.id}
                           className='cursor-pointer font-inter'
                           onClick={() => setSelected(operator)}>

                           <td>
                              <div className='flex items-center'>
                                 <span className="mr-3">
                                    <Operator />
                                 </span>

                                 <span className="text-shiga-dark-100 font-medium">
                                    {operator?.operatorId}
                                 </span>
                              </div>
                           </td>

                           <td>
                              <div className="flex items-center">

                                 {operator?.loginToken ? `${operator?.loginToken?.replace(/\S/g, "•")}` : 'N/A'}

                                 <Maybe condition={operator?.loginToken?.length > 0}>
                                    <button className='ml-2' onClick={(e: any) => {
                                       e.stopPropagation();
                                       copyItem(operator?.loginToken, 'Login token');
                                    }}>
                                       <CopyIcon />
                                    </button>
                                 </Maybe>

                              </div>
                           </td>

                           <td>
                              <div
                                 className={`border rounded-md capitalize py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                                 ${isActive ? 'border-shiga-green-50 text-shiga-green-100'
                                       : isDisabled ? 'text-shiga-orange-100 border-shiga-orange-50'
                                          : 'border-shiga-red-40 text-shiga-red-100'}`}>
                                 {operator?.status?.toLowerCase()}
                              </div>
                           </td>

                           <td>
                              <div className="text-shiga-dark-100 font-medium">
                                 {deviceName}
                              </div>
                           </td>

                           <td>
                              <div className='font-medium'>
                                 {moment(operator?.createdAt).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                           <td>
                              <CustomTableDropdown
                                 options={[
                                    {
                                       Icon: Sync,
                                       permission: true,
                                       title: 'Regenerate Token',
                                       onClick: () => {
                                          setSelected(operator);
                                          setTokenModalOpen(true);
                                       },
                                    },
                                    {
                                       Icon: Delete,
                                       permission: true,
                                       title: 'Delete Operator',
                                       iconClass: 'text-shiga-red-50',
                                       titleClass: 'text-shiga-red-50',
                                       onClick: () => handleDelete(operator)
                                    },
                                 ]}
                              />
                           </td>
                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>

         <OperatorTokenModal
            selected={selected}
            isOpen={tokenModalOpen}
            handleRegen={handleRegen}
            setIsOpen={setTokenModalOpen}
         />

         <DeleteOperatorModal
            selected={selected}
            isOpen={deleteOpen}
            setIsOpen={setDeleteOpen}
            fetchOperators={fetchOperators}
         />

      </>
   )
}

export default OperatorsTable