
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import ContentLoading from "components/common/ContentLoading";
import Maybe from "components/common/Maybe";
import moment from "moment";


const Sidebar = (props: any) => {
   // eslint-disable-next-line
   const { selected, copyItem, loading, details } = props;

   const isActive = selected?.status === 'ACTIVE';
   // const isInactive = device?.status === 'INACTIVE';
   // const isDisabled = device?.status === 'DISABLED';

   return (
      <>
         <div className="w-[220px] pt-10 pl-10 pr-6 min-h-screen flex-shrink-0 hidden lg:block border-r border-gray-100 fixed top-20">

            <div className="mt-6">
               <div className="font-ojah text-xl text-shiga-dark-100 mb-8">
                  Details
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Synced</div>
                  <div className="text-shiga-dark-100">
                     <ContentLoading loading={loading} data={selected?.lastSynced ?? 'N/A'} />
                  </div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Device Code</div>
                  <div className="text-shiga-dark-100 flex items-center">
                     {selected?.code ? selected?.code?.substring(0, 20) + '...' : 'N/A'}

                     <Maybe condition={selected?.code?.length > 0}>
                        <button className='ml-1.5' onClick={() => copyItem(selected?.code, 'Device code')}>
                           <CopyIcon />
                        </button>
                     </Maybe>
                  </div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Status</div>
                  <div
                     className={`border rounded-md capitalize py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                              ${isActive ? 'border-shiga-green-50 text-shiga-green-100'
                           : 'border-shiga-red-40 text-shiga-red-100'}`}>
                     {selected?.status?.toLowerCase()}
                  </div>
               </div>

               <div className="mb-5 space-y-2">
                  <div className="text-shiga-gray-300">Date Added</div>
                  <div className='text-shiga-dark-300'>
                     {moment(selected?.createdAt).format('MMM D, YYYY [at] h:mma')}
                  </div>
               </div>

            </div>

         </div>

      </>
   )
}


export default Sidebar