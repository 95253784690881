import { useEffect } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import { ReactComponent as Sync } from "assets/images/icons/refresh-gray.svg";
import { ReactComponent as Delete } from "assets/images/icons/Delete.svg";
import { ReactComponent as Operator } from "assets/images/icons/operator-sm.svg";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import EmptyUI from 'components/common/EmptyUi';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateAgentToken, getAllTerminalAgents, resetTerminalParams } from 'store/terminals/action';
import EmptyAgents from "assets/images/icons/user-avatar.svg";
import ShigaButton from 'components/common/form/ShigaButton';
import FilterPagination from './FilterPagination';
import CustomTableDropdown from 'components/common/CustomTableDropdown';
import AgentTokenModal from './AgentTokenModal';
import DeleteAgentModal from './DeleteAgentModal';

type Props = {
   toggleAddAgent: () => void
}

const AgentsTable = ({ toggleAddAgent }: Props) => {
   const { dispatch, useState, copyItem, isLocalEnv } = useToolkit();
   const navigate = useNavigate();

   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });
   const [selected, setSelected] = useState<any>(null);
   const [deleteOpen, setDeleteOpen] = useState(false);
   const [tokenModalOpen, setTokenModalOpen] = useState(false);

   const location = useLocation();
   const {
      getAgentsLoading: agentsLoading,
      getAgentsData:
      agents,
      createAgentData,
      agentTokenData,
      deleteAgentsLoading,
      agentTokenLoading: tokenLoading
   } = useSelector((state: RootState) => state.terminals);

   const isValidAgents = agentsLoading === false && agents?.data?.agents && agents?.data?.agents?.length > 0;
   const isFirstTimer = agentsLoading === false && !window?.location?.search?.includes('?')

   const fetchAgents = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "status", "string", "", "status");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");
      params = processParams(urlQueryParams, params, "environment", "string", "", "environment");

      setListParams(params);
      dispatch(getAllTerminalAgents(params));
   }

   const handleRegen = () => {
      dispatch(generateAgentToken({ id: selected?.id }))
   }

   const handleDelete = (operator: any) => {
      setSelected(operator);
      setDeleteOpen(true);
   }

   const reset = () => {
      fetchAgents();
      setTimeout(() => {
         dispatch(resetTerminalParams())
      }, 1500);
   }

   useEffect(() => {
      fetchAgents();
      // eslint-disable-next-line
   }, [location]);

   useEffect(() => {
      if (createAgentData?.success === true) {
         toggleAddAgent()
         reset();
      }
      // eslint-disable-next-line
   }, [createAgentData]);

   useEffect(() => {
      if (agentTokenData?.success === true) {
         setTokenModalOpen(false)
         reset();
      }
      // eslint-disable-next-line
   }, [agentTokenData]);

   useEffect(() => {
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);

   return (

      <>
         <div className="pb-24">

            <Maybe condition={!isFirstTimer || isValidAgents}>
               <div className="flex items-center justify-between">

                  <div className="flex-grow">
                     <FilterPagination
                        logs={agents?.data?.meta}
                     />
                  </div>

                  <div className="pb-5 ml-10">
                     <ShigaButton
                        darkBg
                        disabled={!isLocalEnv}
                        text="Generate an agent"
                        onClick={() => isLocalEnv ? toggleAddAgent() : null}
                        loading={agentsLoading || tokenLoading || deleteAgentsLoading}
                     />
                  </div>
               </div>
            </Maybe>

            <Table>

               <Maybe condition={(agentsLoading === false && !agents?.data?.agents) || (isValidAgents)}>
                  <Table.Head>
                     <th>Agent ID</th>
                     <th>Login Token</th>
                     <th>Status</th>
                     <th>IP Address</th>
                     <th>date generated</th>
                     <th></th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     colspan={5}
                     loading={agentsLoading}
                     loadingText="Fetching AGents"
                     data={agents?.data?.agents}
                     total={agents?.data?.agents?.length || 0}
                     emptyUI={
                        <div className='flexed flex-col'>
                           <EmptyUI
                              icon={EmptyAgents}
                              header={isFirstTimer ? "No Agents Yet" : "No results found"}
                              subheader={isFirstTimer ? "All agents you generate to create and assign cards on cards.payshiga.com would appear here" : ''}
                           />

                           <Maybe condition={isFirstTimer}>
                              <ShigaButton
                                 darkBg
                                 text="Generate an Agent"
                                 disabled={!isLocalEnv}
                                 onClick={() => isLocalEnv ? toggleAddAgent() : null}
                              />
                           </Maybe>
                        </div>
                     }
                  />

                  {agentsLoading === false && agents?.data?.agents?.map((agent: any, index: number) => {

                     const isActive = agent?.status === 'ACTIVE';
                     // const isInactive = agent?.status === 'INACTIVE';
                     const isDisabled = agent?.status === 'DISABLED';

                     return (
                        <tr
                           key={agent.id}
                           className='cursor-pointer font-inter'
                           onClick={() => setSelected(agent)}>

                           <td>
                              <div className='flex items-center'>
                                 <span className="mr-3">
                                    <Operator />
                                 </span>

                                 <span className="text-shiga-dark-100 font-medium">
                                    {agent?.agentId}
                                 </span>
                              </div>
                           </td>

                           <td>
                              <div className="flex items-center">

                                 {agent?.loginToken ? `${agent?.loginToken?.replace(/\S/g, "•")}` : 'N/A'}

                                 <Maybe condition={agent?.loginToken?.length > 0}>
                                    <button className='ml-2' onClick={(e: any) => {
                                       e.stopPropagation();
                                       copyItem(agent?.loginToken, 'Login token');
                                    }}>
                                       <CopyIcon />
                                    </button>
                                 </Maybe>

                              </div>
                           </td>

                           <td>
                              <div
                                 className={`border rounded-md capitalize py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                                 ${isActive ? 'border-shiga-green-50 text-shiga-green-100'
                                       : isDisabled ? 'text-shiga-orange-100 border-shiga-orange-50'
                                          : 'border-shiga-red-40 text-shiga-red-100'}`}>
                                 {agent?.status?.toLowerCase()}
                              </div>
                           </td>

                           <td>
                              <div className="text-shiga-dark-100 font-medium">
                                 {agent?.accessIp ?? 'N/A'}
                              </div>
                           </td>

                           <td>
                              <div className='font-medium'>
                                 {moment(agent?.createdAt).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                           <td>
                              <CustomTableDropdown
                                 options={[
                                    {
                                       Icon: Sync,
                                       permission: true,
                                       title: 'Regenerate Token',
                                       onClick: () => {
                                          setSelected(agent);
                                          setTokenModalOpen(true);
                                       },
                                    },
                                    {
                                       Icon: Delete,
                                       permission: true,
                                       title: 'Delete agent',
                                       iconClass: 'text-shiga-red-50',
                                       titleClass: 'text-shiga-red-50',
                                       onClick: () => handleDelete(agent)
                                    },
                                 ]}
                              />
                           </td>
                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>

         <AgentTokenModal
            selected={selected}
            isOpen={tokenModalOpen}
            handleRegen={handleRegen}
            setIsOpen={setTokenModalOpen}
         />

         <DeleteAgentModal
            selected={selected}
            isOpen={deleteOpen}
            setIsOpen={setDeleteOpen}
            fetchAgents={fetchAgents}
         />

      </>
   )
}

export default AgentsTable