
const formatDate = (dateString: any): string => {
   const date = new Date(dateString);
   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

   const day = date.getDate();
   const month = months[date.getMonth()];
   const year = date.getFullYear();

   const ordinalSuffix = (n: number) => {
      if (n > 3 && n < 21) return "th";
      switch (n % 10) {
         case 1: return "st";
         case 2: return "nd";
         case 3: return "rd";
         default: return "th";
      }
   };

   return `${month} ${day}${ordinalSuffix(day)}, ${year}`;
};

export const todayFormatted = formatDate(new Date());

export const groupNotificationsByDate = (notifications: any) => {
   const groups: Record<string, Notification[]> = {};

   notifications?.forEach((notification: any) => {
      const date = formatDate(notification.createdAt);
      if (!groups[date]) {
         groups[date] = [];
      }
      groups[date].push(notification);
   });

   return Object.entries(groups)?.map(([date, notifications]) => ({
      date,
      notifications: notifications?.sort(
         (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ),
   }))?.sort((a: any, b: any) =>
      new Date(b.notifications[0].createdAt).getTime() -
      new Date(a.notifications[0].createdAt).getTime()
   );
};