import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { Input } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";
import { deleteTerminalAgent, resetTerminalParams } from 'store/terminals/action';


type Props = {
   selected: any;
   isOpen: boolean;
   fetchAgents: () => void;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const defaultForm = {
   password: '',
}

const DeleteAgentModal = ({ isOpen, setIsOpen, selected, fetchAgents }: Props) => {

   const [form, setForm] = useState(defaultForm)
   const { dispatch, useSelector } = useToolkit();
   const { deleteAgentsLoading, deleteAgentsData } = useSelector((state: RootState) => state.terminals)

   const formIsInvalid = form?.password?.length < 8;

   const tips = [
      'You will unlink and unsync this agent from your business account ',
      'This agent will not be able to log in to '
   ]

   const handleSubmit = (e: any) => {
      e.preventDefault();
      dispatch(deleteTerminalAgent({ id: selected?.id, password: form.password }));
   }

   useEffect(() => {
      if (deleteAgentsData?.success === true) {
         setIsOpen(false);
         setForm(defaultForm)
         fetchAgents()

         setTimeout(() => {
            dispatch(resetTerminalParams())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [deleteAgentsData])

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Delete this Agent'
         isButtonLoading={false}>

         <div className='w-full relative'>

            <div className="text-shiga-gray-100 font-medium text-sm pb-3">
               BY DELETING THIS AGENT:
            </div>

            <div className='p-4 rounded-2xl space-y-4 mb-5'>

               {tips.map((item, idx) => (
                  <span key={idx} className="flex items-start">

                     <div className="pt-1 mr-2.5">
                        <IconInfo className="w-4 h-4" />
                     </div>

                     <p className="text-shiga-dark-100">
                        {item}

                        {idx === 1 &&
                           <span className='font-medium underline'>
                              cards.payshiga.com
                           </span>
                        }
                     </p>
                  </span>
               ))}
            </div>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-y border-shiga-gray-50 space-y-7">

                  <Input
                     type="password"
                     required={true}
                     value={form.password}
                     placeholder='Enter password'
                     label='Enter your account password to confirm'
                     onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                  />

               </div>

               <div className="mt-5">
                  <ShigaButton
                     danger
                     fullWidth
                     type='submit'
                     text="Delete Agent"
                     disabled={formIsInvalid}
                     loading={deleteAgentsLoading}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default DeleteAgentModal