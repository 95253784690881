const defaultProductForm = {
   productName: '',
   categoryId: '',
   price: '',
   weightUnit: '',
   weight: '',
   quantity: '',
   phoneNumber: '',
}

export {
   defaultProductForm
}