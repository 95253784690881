import { useEffect } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import EmptyLogs from "assets/images/icons/lined-globe.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import { getWebhookLogs, resetWebhookParams, retryWebhookLog } from 'store/webhooks/action';
import EmptyUI from 'components/common/EmptyUi';
import { Loader } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
// import LogDetailsModal from './log-details/LogDetailsModal';
import FilterPagination from './FilterPagination';
import { getTerminalDeviceTrx } from 'store/terminals/action';

type Props = {
   selected: any;
}

const DeviceTrxTable = ({ selected }: Props) => {
   const { useState, copyItem, toastError } = useToolkit();
   const dispatch = useAppDispatch();
   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });
   const location = useLocation();
   const navigate = useNavigate();
   // const [selected, setSelected] = useState<any>(null);
   const [detailsShown, setDetailsShown] = useState(false);

   const { deviceTrxLoading: trxLoading, deviceTrxData: transactions } = useSelector((state: RootState) => state.terminals);
   const trxAreValid = trxLoading === false && transactions?.data && transactions?.data?.transactions?.length > 0;

   console.log('transactions', transactions)

   const fetchTransactions = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "messageStatus", "string", "", "messageStatus");
      params = processParams(urlQueryParams, params, "environment", "string", "", "environment");

      setListParams(params);
      dispatch(getTerminalDeviceTrx({ id: selected?.id, params }));
   }


   const handleAction = (log: any) => {
      const id = log?.id;

      if (!id || id === undefined) {
         toastError('Unable to retry logs without a valid ID');
      } else {
         dispatch(retryWebhookLog(id))
      }
   }

   const handleClick = (log: any) => {
      // setSelected(log);
      setDetailsShown(true);
   }

   useEffect(() => {
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      fetchTransactions();
      // eslint-disable-next-line
   }, [location]);

   // useEffect(() => {
   //    if (retryWebhookData?.success === true) {
   //       setDetailsShown(false);
   //       fetchTransactions();

   //       setTimeout(() => {
   //          dispatch(resetWebhookParams())
   //       }, 1500);
   //    }
   //    // eslint-disable-next-line
   // }, [retryWebhookData]);

   return (

      <>
         <div className="pb-24">

            <FilterPagination data={transactions} />

            <Table>

               <Maybe condition={(trxLoading === false && !transactions?.data) || (trxAreValid)}>
                  <Table.Head>
                     <th>NARRATION</th>
                     <th>AMOUNT</th>
                     <th>OPERATOR ID</th>
                     <th>REFERENCE</th>
                     <th>DATE CREATED</th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     total={transactions?.data?.transactions?.length || 0}
                     loading={trxLoading}
                     loadingText="Fetching Logs"
                     data={transactions?.data?.transactions}
                     colspan={5}
                     emptyUI={
                        <EmptyUI
                           icon={EmptyLogs}
                           header="No Transactions Yet"
                           subheader=''
                        />
                     }
                  />

                  {trxLoading === false && transactions?.data?.transactions?.map((log: any) => {
                     const isFailed = log?.status === 'failed';
                     const isSuccessful = log?.status === 'success'

                     return (
                        <tr
                           className='cursor-pointer font-inter'
                           key={log.id}
                           onClick={() => handleClick(log)}>
                           <td>

                              <div
                                 className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                              ${isSuccessful ? 'border-shiga-green-50 text-shiga-green-100' : 'border-shiga-red-40 text-shiga-red-100'}`}
                              >
                                 {isSuccessful ? 'Successful' : 'Failed'}
                              </div>
                           </td>

                           <td>
                              <div className='rounded-full py-1.5 px-3 text-sm flexed bg-shiga-gray-20 text-shiga-brown font-medium w-max'>
                                 {log?.event}
                              </div>
                           </td>

                           <td>
                              <div className="flex items-center">

                                 {log?.url ? `${log?.url?.substring(0, 25)}...` : 'N/A'}

                                 <Maybe condition={log?.url?.length > 0}>

                                    <button className='ml-2' onClick={(e: any) => {
                                       e.stopPropagation();
                                       copyItem(log?.url, 'URL');
                                    }}>
                                       <CopyIcon />
                                    </button>

                                 </Maybe>

                              </div>
                           </td>

                           <td>
                              {log?.retryAttempts ?? 'N/A'}
                           </td>

                           <td>
                              <div className='font-medium'>
                                 {moment(log?.lastAttempted).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>

         {/* <LogDetailsModal
            selected={selected}
            isOpen={detailsShown}
            handleAction={handleAction}
            setIsOpen={setDetailsShown}
         /> */}
      </>
   )
}

export default DeviceTrxTable