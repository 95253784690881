import Maybe from 'components/common/Maybe'
import ShigaButton from 'components/common/form/ShigaButton'
import { ReactComponent as Caution } from "assets/images/icons/exclamation-red.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import { ReactComponent as CodeIcon } from "assets/images/icons/code.svg";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'lucide-react';

type CodeRenderProps = {
   title: string;
   snippet: any;
   copyItem: any;
}

const DeliveryDetails = (props: any) => {
   const { selected, copyItem, loading, details, handleAction } = props
   const isFailed = selected?.status === 'failed';
   const isSuccessful = selected?.status === 'success';
   const sample = details?.data[0];

   return (
      <div className='h-full w-11/12 ml-7'>

         <div className="w-full flex items-center justify-between bg-shiga-gray-30 p-2.5 rounded-xl">
            <div className="text-lg text-shiga-dark-100 font-ojah">
               Delivery Attempts
            </div>

            <ShigaButton
               limeBg
               loading={loading}
               onClick={handleAction}
               text={
                  <span>
                     {isSuccessful ? 'Force Retry' : 'Retry'}
                  </span>
               }
            />
         </div>

         <Maybe condition={loading}>
            <div className="py-20 flexed">
               <Loader
                  size='3rem'
                  className="animate-spin"
               />
            </div>
         </Maybe>

         <Maybe condition={!loading}>
            <div className="h-full flex items-start justify-between pt-10">

               <div className="w-[43%]">

                  <Maybe condition={isSuccessful}>
                     {details?.data?.map((item: any) => {
                        return (
                           <div
                              key={item?.id}
                              className="flex items-center mb-6">

                              <div className='bg-shiga-green-50 text-shiga-green-100 font-medium py-1.5 px-3 rounded-xl flex items-center mr-4'>
                                 <FontAwesomeIcon icon="check" />

                                 <span className="ml-1.5">
                                    {`${item?.statusCode} ${item?.statusCode === 200 ? 'OK' : ''}`}
                                 </span>
                              </div>

                              {moment(item?.createdAt).format('MMM D, YYYY | h:mma')}
                           </div>
                        )
                     })}
                  </Maybe>

                  <Maybe condition={isFailed}>
                     {details?.data?.map((item: any) => {
                        return (
                           <div key={item?.id} className="flex items-center mb-6">
                              <div className='bg-shiga-red-40 text-shiga-red-100 font-medium py-px px-2 rounded-xl flex items-center mr-4'>
                                 <Caution />
                                 <span className="ml-2 whitespace-nowrap">
                                    {`${item?.statusCode} ${item?.statusCode === 500 ? 'Internal Server Error' : item?.responseData?.message || 'Error'}`}
                                 </span>
                              </div>

                              {moment(item?.createdAt).format('MMM D, YYYY | h:mma')}
                           </div>
                        )
                     })}
                  </Maybe>
               </div>

               <div className='bg-shiga-gray-50 h-full w-px' />

               <div className="w-[53%] space-y-12">

                  <CodeRender
                     title="Event"
                     copyItem={copyItem}
                     snippet={selected?.event}
                  />

                  <CodeRender
                     copyItem={copyItem}
                     title="Request Header"
                     snippet={sample?.requestHeader}
                  />

                  <CodeRender
                     title="Response"
                     copyItem={copyItem}
                     snippet={sample?.responseData}
                  />

               </div>
            </div>
         </Maybe>

      </div>
   )
}

const CodeRender = ({ title, snippet, copyItem }: CodeRenderProps) => {

   return (
      <div className="w-[80%] ml-auto border border-shiga-gray-50 rounded-xl min-h-40 overflow-scroll snippet-container">
         <div className="w-full bg-shiga-gray-30 p-3 flex items-center justify-between rounded-t-xl">
            <div className="flex items-center">
               <CodeIcon />
               <span className="text-shiga-gray-300 font-medium ml-2">
                  {title}
               </span>
            </div>

            <button onClick={() => copyItem(JSON.stringify(snippet), title)}>
               <CopyIcon />
            </button>
         </div>

         <div className="p-4">
            <pre>
               <code>{JSON.stringify(snippet, null, 2)}</code>
            </pre>
         </div>

      </div>
   )
}

export default DeliveryDetails