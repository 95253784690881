import * as actions from "./actionTypes";
import { RootState } from "store/store";
import { Activity } from "types/activities";
import { BusinessStatus } from "types/business";
import { Card } from "types/card";
import { Overview, Period } from "types/overview";
import { DashboardWallet } from "types/wallet";
import errorReducer from "utils/errorReducer";

interface DataResponse<T> {
  success: boolean;
  statusCode: number;
  message: string;
  data: T;
}

interface InitialState {
  isLoadingActivites: boolean;
  activities: DataResponse<Activity[]> | null;

  isLoadingOverview: boolean;
  overview: DataResponse<Overview> | null;

  isLoadingCards: boolean;
  cards: DataResponse<Card[]> | null;

  isLoadingWallets: boolean;
  wallets: DataResponse<DashboardWallet[]> | null;

  isLoadingChart: boolean;
  chart: DataResponse<Period[]> | null;
  businessStatus: BusinessStatus;

  notificationsLoading: boolean;
  notificationsData: any;
}

const initialState: InitialState = {
  isLoadingOverview: false,
  overview: null,
  isLoadingActivites: false,
  activities: null,
  isLoadingCards: false,
  cards: null,
  isLoadingWallets: false,
  wallets: null,
  isLoadingChart: false,
  chart: null,
  businessStatus: "",

  notificationsLoading: false,
  notificationsData: null,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {

    case actions.GET_NOTIFICATIONS_START:
      return {
        ...state,
        notificationsLoading: true,
        notificationsData: null,
      }

    case actions.GET_NOTIFICATIONS_DONE:

      return {
        ...state,
        notificationsLoading: false,
        notificationsData: action?.payload,
      }

    case actions.GET_NOTIFICATIONS_FAILED:
      errorReducer(action?.payload)
      return {
        ...state,
        notificationsLoading: false,
        notificationsData: action?.payload,
      }

    case actions.GET_DASHBOARD_OVERVIEW_START:
      state = {
        ...state,
        isLoadingOverview: true,
      };
      return state;
    case actions.GET_DASHBOARD_CHART_START:
      state = {
        ...state,
        isLoadingChart: true,
      };
      return state;
    case actions.GET_DASHBOARD_CHART:
      state = {
        ...state,
        isLoadingChart: false,
        chart: action.payload,
      };
      return state;
    case actions.GET_DASHBOARD_OVERVIEW:
      state = {
        ...state,
        isLoadingOverview: false,
        overview: action.payload,
      };
      return state;

    case actions.GET_DASHBOARD_ACTIVITIES:
      state = {
        ...state,
        isLoadingActivites: false,
        activities: action.payload,
      };
      return state;

    case actions.GET_DASHBOARD_CARD:
      state = {
        ...state,
        isLoadingCards: false,
        cards: action.payload,
      };
      return state;

    case actions.GET_DASHBOARD_WALLET:
      sessionStorage.setItem("wallet", JSON.stringify(action.payload.data));
      state = {
        ...state,
        isLoadingWallets: false,
        wallets: action.payload,
      };
      return state;
    case actions.GET_BUSINESS_STATUS:
      state = {
        ...state,
        businessStatus: action.payload.data.business.verificationStatus,
      };
      return state;
    default:
      return state;
  }
}

export const getDashboard = (state: RootState) => state.dashboard;
