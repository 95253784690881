import { useState } from "react";
import NewOperatorModal from "./NewOperatorModal";
import OperatorsTable from "./table/OperatorsTable";

type Props = {}

const OperatorsHomepage = (props: Props) => {
   const [addOperatorOpen, setAddOperatorOpen] = useState(false);
   const toggleNewOperatorModal = () => setAddOperatorOpen(!addOperatorOpen);

   return (
      <div className='w-full'>

         <OperatorsTable
            toggleNewOperatorModal={toggleNewOperatorModal}
         />

         <NewOperatorModal
            isOpen={addOperatorOpen}
            setIsOpen={setAddOperatorOpen}
         />
      </div>
   )
}

export default OperatorsHomepage