import useToolkit from "hooks/useToolkit";
import { useState } from "react";
import NewDeviceModal from "./NewDeviceModal";
import DevicesTable from "./table/DevicesTable";

type Props = {}

const DevicesHomepage = (props: Props) => {
   // eslint-disable-next-line
   const { useSelector } = useToolkit();
   const [addDeviceOpen, setAddDeviceOpen] = useState(false);
   const toggleNewDeviceModal = () => setAddDeviceOpen(!addDeviceOpen);

   return (
      <div className='w-full'>

         <NewDeviceModal
            isOpen={addDeviceOpen}
            setIsOpen={setAddDeviceOpen}
         />

         <DevicesTable
            toggleNewDeviceModal={toggleNewDeviceModal}
         />
      </div>
   )
}

export default DevicesHomepage